body {
  background-color: #dbe6e7;
}

.detail-card {
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.technology {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-header {
  font-weight: bold;
  color: #333;
  padding: 16px;
}

.card-content {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 12px;
  flex-grow: 1;
}

.technologies-container {
  display: flex;
  gap: 8px;
}

.technology-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.links-container {
  display: flex;
  gap: 12px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3a3a3a;
  transition: color 0.3s ease-in-out;
}

.link:hover {
  color: #afafaf;
}

.link-icon {
  margin-right: 4px;
  font-size: 1.2rem;
}

.link-text {
  font-size: 0.9rem;
}

.box-center {
  height: 98vh; 
  display: flex;
  justify-content: center;
  flex-direction: column;
}